<template>
  <Chat class="chat-tab">
    <ChatMessagesPlaceholder v-if="isLoading">
      <ChatMessagePlaceholder
        v-for="i in 10"
        :key="i"
        :me="i % 3 === 0">
      </ChatMessagePlaceholder>
    </ChatMessagesPlaceholder>

    <ChatMessages v-else>
      <ChatMessage
        v-for="(message, i) in messages"
        :key="`${message.id}-${i}`"
        :createdBy="getParticipantName(message.createdBy)"
        :me="isMe(message)"
        :message="message">
      </ChatMessage>
    </ChatMessages>

    <ChatForm
      :loading="submitting"
      :placeholder="$t('hunt.sendMessageToParticipantsPlaceholder')"
      @send="sendMessage">
    </ChatForm>
  </Chat>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      submitting: false,
      participantNames: []
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      participants: 'hunt/participants/participants'
    }),

    messages () {
      const messages = this.$store.getters['hunt/chat/messages'] || []
      return messages.slice().sort((a, b) => a.createdAt - b.createdAt)
    },

    isLoading () {
      return this.$store.getters['hunt/chat/messages'] === null
    }
  },

  methods: {
    async sendMessage (message) {
      if (!message.length) return
      this.submitting = true

      try {
        await this.$store.dispatch('hunt/chat/sendMessage', {
          huntId: this.hunt.id,
          text: message
        })
      } catch (error) {
        this.$notification.danger(error.toString())
      }

      this.submitting = false
    },

    isMe (message) {
      const userId = this.$store.getters['auth/getUserId']
      return message.createdBy === userId
    },

    getParticipantName (participantId) {
      let name = this.participantNames[participantId]

      if (name !== undefined) {
        return name
      }

      const participant = this.getParticipant(participantId)
      name = participant !== null ? participant.getFullName() : this.$i18n.t('hunt.unknownParticipant')
      this.participantNames[participantId] = name

      return name
    },

    getParticipant (participantId) {
      for (let i = 0; i < this.participants.length; i++) {
        if (this.participants[i].id === participantId) {
          return this.participants[i]
        }
      }

      return null
    }
  }
}
</script>

<style scoped>
.chat-tab {
  padding: 0 !important;
}
</style>
